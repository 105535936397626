html {font-size: 16px; line-height: 24px; height: 100%;}
body {
    font-family: 'Montserrat';
    color: #3f4040;
    font-size: 1.1rem;
    line-height: 2rem;
    height: 100%;
}
body.mobile-menu {overflow: hidden;}
#__next {
    height: 100%;
}
#main {
    min-height: calc(100% - 145px);
    padding-bottom: 50px;
    position: relative;
}
h1 {
    font-size: 2.5rem;
}
.bg-home {
    background-color: #f9f9f7;
}
.bg-primary {
    background-color: #1d4360!important;
}
.bg-blue {
    background-color: #1d4360;
}
.bg-pink {
    background-color: #ffecea;
}
.bg-green {
    background-color: #daebea;
}
.red {
    color: #e33c25;
}
.green {
    color:#23a5a1;
}
.blue {
    color: #204647;
}
.bg-red {
    background-color:#e33c25;
}
.black-text {
    color: #060809;;
}
.black-text-light {
    color: #565656;
}
.heading1 {
    line-height: 3rem;
    font-size: 2.5rem;
}
.heading2 {
    font-size: 1.66rem;
    line-height: 2.58rem;
}
.heading3 {
    font-size: 1.16rem;
    line-height: 2rem;
}

/* NAVBAR */
nav.main-nav {
    border-bottom: 1px solid #eeeeee;
}
nav.main-nav .nav-link, nav.main-nav .nav-item {
    color: #141414;
    font-weight:bold;
    font-size: 0.9rem;
    line-height: 1.35rem;
}

nav.main-nav .nav-link.selected, nav.main-nav .nav-item.selected.dropdown>a {
    color: #23a5a1;
}

.navbar-expand-lg .navbar-nav .nav-link {
    color:#666;
    text-transform: uppercase;
}
nav.main-nav .dropdown-toggle::after {
    margin-left: 0.5rem;
    vertical-align: 0.15em;
}
.dropdown-item {
    line-height: 1.5rem;
}

nav.main-nav .nav-login button.btn-link:hover, nav.main-nav .nav-login button.btn-link:focus {
    text-decoration: none;
}
nav.main-nav .nav-login .dropdown-menu {
    left: -90px;
}

nav.main-nav .icon-user {
    color: #23a5a1;
}
nav.main-nav .navbar-nav .dropdown {
    position: static;
}
nav.main-nav .navbar-nav .dropdown .dropdown-menu {
    min-width: 100%;
}
nav.main-nav .navbar-nav .dropdown-menu {
    position: absolute;
}
nav.main-nav .navbar-nav .dropdown .show {
    display: block;
    max-width: 1140px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #23a5a1;
}

/* END NAVBAR */

.btn-outline-primary {
    color:#e33c25;
    border-color: #e33c25;
}
.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus {
    background-color:#e33c25;
    border-color: #e33c25;
}
.btn-primary {
    background-color:#e33c25;
    border-color: #e33c25;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: red;
    border-color: red;
    box-shadow: none;
}

.btn-outline-secondary {
    color: #1f7673;
    border-color:  #1f7673;
}
.btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus {
    background-color:  #23a5a1;
    border-color: #23a5a1;
    color: #fff;
    box-shadow: none;
}
.btn-secondary {
    background-color: #1f7673;
    border-color:  #1f7673;
    color:#fff;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
    background-color:  #23a5a1;
    border-color:  #23a5a1;
    color:#fff;
    box-shadow: none;
}

.btn-link {
    color: #244e7b;
}



/* FOOTER */
.main-footer {
    background-color: #f1f1f1;
    color: #565656;
}
.main-footer .footer-section {
    font-weight: bold;
    color: #0b0b0b;
}
.main-footer ul.footer-list {
    list-style: none;
    padding-left: 0;
}
.main-footer ul.footer-list li {
    line-height: 2rem;
    font-size: 1rem;
    float:left;
}
.main-footer ul.footer-list li a {
    color: #565656;
    font-size: 0.9rem;
}
.main-footer .copyright {
    font-size: 0.8rem;
    line-height: 1.2rem;
}
/* END FOOTER */

/* FORM */
.helper {
    font-size:0.8rem; 
    line-height: 1rem;
}
.custom-select.is-valid, .custom-select.is-invalid  {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}
.form-control.is-valid, .form-control.is-invalid {
    background: #fff;
    padding-right: 0.75rem;
}
input.rbt-input-main {
    text-transform: none;
}
.rbt.is-valid input {
    border-color: #23a5a1;
}

nav.compare .nav-link {
    border-color: #23a5a1; border-radius:0;
}
nav.compare .nav-link.active { background-color:#23a5a1; color: #fff; border-color: #23a5a1;}
.react-datepicker-popper {z-index: 3!important;}
button.react-datepicker__navigation--next {
    border-left-color: #23a5a1;
}
button.react-datepicker__navigation--previous {
    border-right-color: #23a5a1;
}
/* END FORM */

@media(max-width: 991px) {

}
   

/* MD */
@media(max-width:767px) {
    body {
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
}
/* END MD */

/* XS */
@media(max-width:575px) {
    body {
        font-size: 1rem;
        line-height: 1.4rem;
    }
    h1 {
        font-size: 2rem;
    }
    .heading1 {
        font-size: 1.7rem;
        line-height: 2.2rem;
    }
    .heading2 {
        font-size: 1.25rem;
        line-height: 1.9rem;
    }
    .heading3 {
        font-size: 1.1rem;
        line-height: 1.7rem;
    }
    .main-footer ul.footer-list li {
        line-height: 1.9rem;
        font-size: 0.8rem;
        float:none;
    }

    nav.main-nav .navbar-brand img {
        max-width: 90px;
    }
}